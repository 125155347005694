import Dashboard from "layouts/dashboard";
import Icon from "@mui/material/Icon";
import Challenges from "layouts/challenges";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: ["dashboard"],
    canAccess: ['GlobalCommunityAdmin', "All"],
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Challenges",
    key: ["challenges", "challenge"],
    canAccess: ['GlobalCommunityAdmin'],
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/challenges",
    component: <Challenges />,
  },
  {
    type: "collapse",
    name: "Events",
    key: ["events", "event"],
    canAccess: ['GlobalCommunityAdmin'],
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/events",
    component: <Challenges />,
  },
  {
    type: "collapse",
    name: "Support Tool",
    key: ["export-user-activity", "activate-user-reward", "export-user-marketing-details", "challenge-activities"],
    canAccess: ['GlobalCommunityAdmin'],
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/activity",
    children: [
      {
        name: "Export User Activity",
        key: "support_tool/export-user-activity",
        route: "/support_tool/export-user-activity",
        component: <Challenges />,
      },
      {
        name: "Activate User Reward",
        key: "support_tool/activate-user-reward",
        route: "/support_tool/activate-user-reward",
        component: <Challenges />,
      },
      {
        name: "Export User Marketing Details",
        key: "support_tool/export-user-marketing-details",
        route: "/support_tool/export-user-marketing-details",
        component: <Challenges />,
      },
      {
        name: "Export Challenge Activities",
        key: "support_tool/export-challenge-activities",
        route: "/support_tool/export-challenge-activities",
        component: <Challenges />,
      },
      {
        name: "Transfer Activity ",
        key: "support_tool/transfer-user-activity",
        route: "/support_tool/transfer-user-activity",
        component: <Challenges />,
      },
      {
        name: "Remove User",
        key: "support_tool/remove-user",
        route: "/support_tool/remove-user",
        component: <Challenges />,
      },
      {
        name: "Transfer Followers ",
        key: "support_tool/transfer-user-followers",
        route: "/support_tool/transfer-user-followers",
        component: <Challenges />,
      },
      {
        name: "Update Challenge organizer",
        key: "support_tool/update-challenge-organizer",
        route: "/support_tool/update-challenge-organizer",
        component: <Challenges />,
      },
      {
        name: "Copy Reward  ",
        key: "support_tool/copy-reward",
        route: "/support_tool/copy-reward",
        component: <Challenges />,
      },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "Goal",
  //   key: ["goals", "goal"],
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/goals",
  //   component: <Challenges />,
  // },

];

export default routes;
