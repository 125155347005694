import { ActionTypes, CHALLENGE_URL, LOOKUP_URL, REWARD_URL } from "../contants";
import { customeGetService, customePostService, getService, getByIdService, createService, deleteService } from "../services/service";
const XLSX = require('xlsx');

// Get Active Challenges List
const getActiveChallengesList = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_CHALLENGES_LIST_REQUEST, })
    let userDetails = JSON.parse(localStorage.getItem('UserDetails'))
    getService('Challenge/Own/' + userDetails.userId + '?type=ACTIVE').then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_CHALLENGES_LIST_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_CHALLENGES_LIST_FAIL, data: response.response }) }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_CHALLENGES_LIST_FAIL, data: error })
      }
    )
  }
};

// Create New Chalange
const createChalange = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.CREATE_CHALANGE_REQUEST, })
    createService('Challenge/Create', params).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.CREATE_CHALANGE_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.CREATE_CHALANGE_FAIL, data: response.response }) }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.GET_ALL_ACTIVE_CHALLENGES_LIST_FAIL, data: error })
      }
    )
  }
};

// Get Challenge Options
const getChallengeOptions = (params = null) => {
  return (Dispatch) => {

    Dispatch({ type: ActionTypes.GET_ACTIVE_CHALLENGES_OPTIONS_REQUEST, })
    customeGetService(LOOKUP_URL + 'Challenge/Options').then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_ACTIVE_CHALLENGES_OPTIONS_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_ACTIVE_CHALLENGES_OPTIONS_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.GET_ACTIVE_CHALLENGES_OPTIONS_FAIL, data: error }) }
    )
  }
};

// ClearC Callenge Success Responce
const clearChallengeResponce = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.CLEAR_CHALLENGES_RESPONCE, })
  }
}

// Get Competition Community
const getcompetitionCommunity = (params = null) => {
  return (Dispatch) => {

    Dispatch({ type: ActionTypes.GET_COMPETITION_COMMUNITY_REQUEST, })
    customePostService(CHALLENGE_URL + 'Challenge/GetCompetitingCommunity', params).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_COMPETITION_COMMUNITY_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_COMPETITION_COMMUNITY_FAIL, data: response.response }) }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.GET_COMPETITION_COMMUNITY_FAIL, data: error })
      }
    )
  }
};

// Get Challenges Datails
const getChallengesDatailsById = (challengeId = null) => {
  return (Dispatch) => {

    Dispatch({ type: ActionTypes.GET_CHALLENGES_DETAILS_BY_ID_REQUEST, })
    getByIdService('Challenge/Details', challengeId).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_CHALLENGES_DETAILS_BY_ID_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.GET_CHALLENGES_DETAILS_BY_ID_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.GET_CHALLENGES_DETAILS_BY_ID_FAIL, data: error }) }
    )
  }
};

const resetChallengesDatailsById = () => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.RESET_CHALLENGES_DETAILS_BY_ID, })
  }
}

// Get Challenge Participants By  Organizer 
const getParticipantsByOrganizer = (params = null) => {
  return (Dispatch) => {

    Dispatch({ type: ActionTypes.GET_PARTICIPANTS_BY_ORGANIZER_REQUEST, })
    customePostService(CHALLENGE_URL + 'Challenge/GetParticipantsByOrganizer', params).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_PARTICIPANTS_BY_ORGANIZER_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_PARTICIPANTS_BY_ORGANIZER_FAIL, data: response.response }) }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.GET_PARTICIPANTS_BY_ORGANIZER_FAIL, data: error })
      }
    )
  }
};

// Get Reward Options
const getRewardOptions = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.GET_REWARD_OPTIONS_OPTIONS_REQUEST, })
    customeGetService(LOOKUP_URL + 'Reward/Options', params).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_REWARD_OPTIONS_OPTIONS_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_REWARD_OPTIONS_OPTIONS_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.GET_REWARD_OPTIONS_OPTIONS_FAIL, data: error }) }
    )
  }
};

// Get Update Challenge
const updateChallengeById = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.UPDATE_CHALANGE_REQUEST, })
    createService('Challenge/Update', params).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.UPDATE_CHALANGE_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.UPDATE_CHALANGE_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.UPDATE_CHALANGE_FAIL, data: error }) }
    )
  }
};

// Get Remove Challenge
const removeChallenge = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.REMOVE_CHALANGE_REQUEST, })
    deleteService('Challenge/Delete/' + params).then(
      (response) => {
        if (response?.status === 200) {
          return Dispatch({ type: ActionTypes.REMOVE_CHALANGE_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.REMOVE_CHALANGE_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.REMOVE_CHALANGE_FAIL, data: error }) }
    )
  }
};

// Get Challenge Related Activities
const GetChallengeRelatedActivities = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.GET_CHALLENGE_RELATED_ACTIVITIES_REQUEST, })
    customeGetService(CHALLENGE_URL + 'Challenge/GetChallengeRelatedActivities?challengeId=' + params.challengeId + '&startDate=' + params.startDate + '&endDate=' + params.endDate)
      .then(
        (response) => {
          if (response?.status === 200) {
            downloadExcelXLSX(response.data, "ChallengeRelatedActivities");
            return Dispatch({ type: ActionTypes.GET_CHALLENGE_RELATED_ACTIVITIES_SUCESS, data: response })
          }
          else { return Dispatch({ type: ActionTypes.GET_CHALLENGE_RELATED_ACTIVITIES_FAIL, data: response.response }) }
        },
        (error) => {
          return Dispatch({ type: ActionTypes.GET_CHALLENGE_RELATED_ACTIVITIES_FAIL, data: error })
        }
      )
  }
};

// Get Application Options List
const GetUserChallengeList = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_REQUEST, })
    customeGetService(REWARD_URL + 'Reward/GetChallengeRelatedToReward?isReward=false').then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.GET_REWAED_CHALLENGES_FAIL, data: error }) }
    )
  }
};

const recalculateLeaderboardAction = (challengeId) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.RECACULATE_lEADERBOARD_REQUEST, })
    customeGetService(CHALLENGE_URL + 'Challenge/LeaderboardRecalculateWithoutCompititer?challengeId=' + challengeId).then(
      (response) => {
        if (response) {
          return Dispatch({ type: ActionTypes.RECACULATE_lEADERBOARD_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.RECACULATE_lEADERBOARD_FAIL, data: response.response }) }
      },
      (error) => { return Dispatch({ type: ActionTypes.RECACULATE_lEADERBOARD_FAIL, data: error }) }
    )
  }
};

function downloadExcelXLSX(base64Txt, fileName) {

  let day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
  let month = new Date().getMonth() < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
  let year = new Date().getFullYear();
  let today = day + month + year

  let linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Txt}`;
  let downloadLink = document.createElement('a');
  downloadLink.setAttribute('download', `${fileName}${today}.xlsx`);
  downloadLink.href = linkSource;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

// Get Organizers List 
const getOrganizersList = (params = null) => {
  return (Dispatch) => {

    Dispatch({ type: ActionTypes.GET_ORGANIZER_REQUEST, })
    customeGetService(LOOKUP_URL + 'Organizer/GetOrganizer', params).then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_ORGANIZER_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_ORGANIZER_FAIL, data: response.response }) }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.GET_ORGANIZER_FAIL, data: error })
      }
    )
  }
};


// Get Organizers List 
const changeChallengeOrganizer = (params = null) => {
  return (Dispatch) => {

    Dispatch({ type: ActionTypes.CHANGE_CHALLENGE_ORGANIZER_REQUEST, })
    customePostService(CHALLENGE_URL + 'Challenge/ChangeOrganizer', params).then(
      (response) => {
        if (response?.status === 200) {
          return Dispatch({ type: ActionTypes.CHANGE_CHALLENGE_ORGANIZER_SUCESS, data: response })
        }
        else {
          return Dispatch({ type: ActionTypes.CHANGE_CHALLENGE_ORGANIZER_FAIL, data: response })
        }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.CHANGE_CHALLENGE_ORGANIZER_FAIL, data: error })
      }
    )
  }
};

// Get Past Challenges List
const getPastChallengesList = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.GET_ALL_PAST_CHALLENGES_LIST_REQUEST, })
    let userDetails = JSON.parse(localStorage.getItem('UserDetails'))
    getService('Challenge/Own/' + userDetails.userId + '?type=PAST').then(
      (response) => {
        if (response?.data) {
          return Dispatch({ type: ActionTypes.GET_ALL_PAST_CHALLENGES_LIST_SUCESS, data: response.data })
        }
        else { return Dispatch({ type: ActionTypes.GET_ALL_PAST_CHALLENGES_LIST_FAIL, data: response.response }) }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.GET_ALL_PAST_CHALLENGES_LIST_FAIL, data: error })
      }
    )
  }
};

// Get Past Challenges List
const copyRewardFromOtherChallengeAction = (params = null) => {
  return (Dispatch) => {
    Dispatch({ type: ActionTypes.COPY_REWARD_FROM_OTHER_CHALLENGE_REQUEST, })
    customePostService(CHALLENGE_URL + 'Challenge/CopyRewardFromOtherChallenge', params).then(
      (response) => {
        if (response?.status === 200) {
          return Dispatch({ type: ActionTypes.COPY_REWARD_FROM_OTHER_CHALLENGE_SUCESS, data: response })
        }
        else { return Dispatch({ type: ActionTypes.COPY_REWARD_FROM_OTHER_CHALLENGE_FAIL, data: response }) }
      },
      (error) => {
        return Dispatch({ type: ActionTypes.COPY_REWARD_FROM_OTHER_CHALLENGE_FAIL, data: error })
      }
    )
  }
};

export {
  createChalange,
  getActiveChallengesList,
  getChallengeOptions,
  getChallengesDatailsById,
  resetChallengesDatailsById,
  getcompetitionCommunity,
  getParticipantsByOrganizer,
  getRewardOptions,
  clearChallengeResponce,
  updateChallengeById,
  removeChallenge,
  GetChallengeRelatedActivities,
  GetUserChallengeList,
  recalculateLeaderboardAction,
  getOrganizersList,
  changeChallengeOrganizer,
  getPastChallengesList,
  copyRewardFromOtherChallengeAction,
}