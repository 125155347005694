import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import FormControl from "@mui/material/FormControl";
import { Icon, Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { confirmAlert } from "react-confirm-alert";

import { Formik, ErrorMessage, Field, Form, } from 'formik';
import * as Yup from 'yup';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { getActiveChallengesList, getPastChallengesList, copyRewardFromOtherChallengeAction } from "redux/actions/challengeActions";


function CopyRewardFromOtherChallenge({
  activeChallengeReducer, pastChallengeReducer, copyRewardFromOtherChallengeReducer,
  getActiveChallengesListAction, getPastChallengesListAction, copyRewardFromOtherChallengeAction, }) {

  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);
  const [filterdpastChallenges, setFilterdpastChallenges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formInitialValues = {
    "activeChallenge": null,
    "pastChallenge": null,
  };

  const formValidationSchema = {
    activeChallenge: Yup.object().required("Please choose active challenge."),
    pastChallenge: Yup.object().required("Please choose past challenge."),
  };

  useEffect(() => {
    getActiveChallengesListAction();
    getPastChallengesListAction();
  }, []);


  useEffect(() => {
    if (copyRewardFromOtherChallengeReducer?.sucess) {
      setIsLoading(false);
      setTimeout(() => {
        navigate("/support_tool/copy-reward")
      }, 2000);
    } else if (copyRewardFromOtherChallengeReducer?.sucess == false && copyRewardFromOtherChallengeReducer?.loading == false) {
      setIsLoading(false);
    }
  }, [copyRewardFromOtherChallengeReducer])


  useEffect(() => {
    if (pastChallengeReducer?.pastChallengesList) {
      setFilterdpastChallenges(pastChallengeReducer?.pastChallengesList.filter(record => record.addReward === "Yes"))
    }
  }, [pastChallengeReducer])


  const onFormSubmit = (values, actions) => {
    confirmAlert({
      title: "",
      message: "Are you sure to copy reward?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setIsLoading(true)
            copyRewardFromOtherChallengeAction({
              fromChallengeId: values.activeChallenge.challengeId,
              toChallengeId: values.pastChallenge.challengeId,
            })
          }
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mx={0} mb={2} mt={3} py={3} px={2} variant="gradient" bgColor="success" borderRadius="lg" coloredShadow="info">
          <MDTypography variant="h6" color="white">
            Copy Reward
          </MDTypography>
        </MDBox>
        {isError && (
          <MDSnackbar
            color="error" icon="warning" title={isError} content={isError} open={isError} bgWhite
            onClose={() => { setIsError(false) }}
            close={() => { setIsError(false) }}
          />
        )}
        <MDBox mb={3}>
          {(activeChallengeReducer?.loading ||
            pastChallengeReducer?.loading
            || isLoading) &&
            <CustomSpinner />}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Formik
              enableReinitialize={true}
              initialValues={formInitialValues}
              validationSchema={Yup.object().shape(formValidationSchema)}
              onSubmit={onFormSubmit}
            >
              {formikProps => (
                <Form>
                  <Card>
                    {/* <MDBox pt={3} px={2}>
                            <MDTypography variant="h6" fontWeight="medium">Transfer User Activity </MDTypography>
                          </MDBox> */}
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={3} px={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Field
                            as={Autocomplete}
                            name="pastChallenge"
                            options={filterdpastChallenges}
                            defaultValue={formikProps.values?.pastChallenge}
                            value={formikProps.values?.pastChallenge}
                            onChange={(event, value) => {
                              formikProps.setFieldValue("pastChallenge", value);
                            }}
                            getOptionLabel={(option) => option.challengeName}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.challengeId}>

                                {option?.challengeImagesUrl && option?.challengeImagesUrl?.length > 0
                                  ? <img
                                    srcSet={option.challengeImagesUrl[0]}
                                    src={option.challengeImagesUrl[0]}
                                    alt={option.challengeName}
                                    className="communityImg"
                                  />
                                  : <Icon fontSize={"large"} className="communityImg communityImgIcon"  >account_circle</Icon>
                                }
                                &nbsp;&nbsp;
                                <span>{option.challengeName}</span>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Past Challenge"
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                          <ErrorMessage name="pastChallenge" component="div" className="error-message" />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Field
                            as={Autocomplete}
                            name="activeChallenge"
                            options={activeChallengeReducer?.challengesList}
                            defaultValue={formikProps.values?.activeChallenge}
                            value={formikProps.values?.activeChallenge}
                            onChange={(event, value) => {
                              formikProps.setFieldValue("activeChallenge", value);
                            }}
                            getOptionLabel={(option) => option.challengeName}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.challengeId}>
                                {option?.challengeImagesUrl && option?.challengeImagesUrl?.length > 0
                                  ? <img
                                    srcSet={option.challengeImagesUrl[0]}
                                    src={option.challengeImagesUrl[0]}
                                    alt={option.challengeName}
                                    className="communityImg"
                                  />
                                  : <Icon fontSize={"large"} className="communityImg communityImgIcon"  >account_circle</Icon>
                                }
                                &nbsp;&nbsp;
                                <span>{option.challengeName}</span>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Active Challenge"
                                inputProps={{
                                  ...params.inputProps,

                                }}
                              />
                            )}
                          />
                          <ErrorMessage name="activeChallenge" component="div" className="error-message" />
                        </FormControl>
                      </Grid>

                    </Grid>
                  </Card>
                  <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                    <MDButton type="submit" size="medium" color="success">Copy Rewards </MDButton>
                    &nbsp;
                    <Link to="/dashboard">
                      <MDButton type="button" size="medium" color="error">Back</MDButton>
                    </Link>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </LocalizationProvider>
        </MDBox>
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    activeChallengeReducer: state.challengeReducer,
    pastChallengeReducer: state.pastChallengeReducer,
    copyRewardFromOtherChallengeReducer: state.copyRewardFromOtherChallengeReducer,
  };
};

export default connect(mapStateToProps, {
  getActiveChallengesListAction: getActiveChallengesList,
  getPastChallengesListAction: getPastChallengesList,
  copyRewardFromOtherChallengeAction: copyRewardFromOtherChallengeAction,
})(CopyRewardFromOtherChallenge);