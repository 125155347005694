import { ActionTypes, msalConfig } from "../contants";
import { PublicClientApplication, EventType } from "@azure/msal-browser";

const errorReducer = (state = {}, action) => {
  if (action?.data?.status == 401) {
    localStorage.clear();
    window.location = "/";
    return null;
  }
  switch (action.type) {
    case ActionTypes.USER_LOGIN_FAILED:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ALL_ACTIVE_CHALLENGES_LIST_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ACTIVE_CHALLENGES_OPTIONS_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_PARTICIPANTS_BY_ORGANIZER_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.CREATE_CHALANGE_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_CHALLENGES_DETAILS_BY_ID_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.UPDATE_CHALANGE_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.REMOVE_CHALANGE_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_CHALLENGE_RELATED_ACTIVITIES_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_USER_CHALLENGES_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ALL_ACTIVE_EVENT_LIST_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ALL_ACTIVE_EVENT_OPTIONS_LIST_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.CREATE_EVENT_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_EVENT_PARTICIPANTS_BY_ORGANIZER_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_EVENT_DETAILS_BY_ID_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.UPDATE_EVENT_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.REMOVE_EVENT_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ALL_GOLES_LIST_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ALL_ACTIVE_GOALS_OPTIONS_LIST_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.CREATE_GOAL_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_REWARD_OPTIONS_OPTIONS_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ACTIVE_TYPE_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_COMPETITION_COMMUNITY_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ACTIVE_OPTIONS_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ALL_CHALLENGE_OPTIONS_LIST_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ALL_ACTIVE_USERS_LIST_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ACTIVITY_TYPES_CONTENT_PREFRENCES_LIST_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_APPLICATION_OPTIONS_LIST_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.CREATE_ACTIVE_USER_MARKETING_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_USER_MARKETING_DATA_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_REWAED_CHALLENGES_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.RECACULATE_lEADERBOARD_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_REWAED_CHALLENGES_PARTICIPANTS_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.ACTIVE_SEARCH_USER_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.GET_ACTIVE_EXEL_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.TRANSFER_USER_ACTIVITY_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.REMOVE_USER_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.TRANSFER_USER_FOLLOWER_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.CHANGE_CHALLENGE_ORGANIZER_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    case ActionTypes.COPY_REWARD_FROM_OTHER_CHALLENGE_FAIL:
      return {
        message: action.data.message,
        description: action.data.message,
      };
    // Default
    default:
      return { error: "" };
  }
};

const successReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_CHALANGE_SUCESS:
      return {
        message: action.data.message,
        Description: "Challenge created successfully.",
      };
    case ActionTypes.UPDATE_CHALANGE_SUCESS:
      return {
        message: action.data.message,
        Description: "Challenge updated successfully.",
      };
    case ActionTypes.CREATE_EVENT_SUCESS:
      return {
        message: action.data.message,
        Description: "Event created successfully.",
      };
    case ActionTypes.UPDATE_EVENT_SUCESS:
      return {
        message: action.data.message,
        Description: "Event updated successfully.",
      };
    case ActionTypes.REMOVE_EVENT_SUCESS:
      return {
        message: action.data.message,
        Description: "Event removed successfully.",
      };
    case ActionTypes.CREATE_GOAL_SUCESS:
      return {
        message: action.data.message,
        Description: "Goal created successfully.",
      };
    case ActionTypes.GET_ACTIVE_EXEL_SUCESS:
      return {
        message: "User activity exported successfully.",
        Description: "User activity exported successfully.",
      };
    case ActionTypes.CREATE_ACTIVE_USER_MARKETING_SUCESS:
      return {
        message: action.data.message,
        Description: action.data.message,
      };
    case ActionTypes.GET_USER_MARKETING_DATA_SUCESS:
      return {
        message: action.data.message,
        Description: action.data.message,
      };
    case ActionTypes.GET_CHALLENGE_RELATED_ACTIVITIES_SUCESS:
      return {
        message: action.data.message,
        Description: action.data.message,
      };
    case ActionTypes.TRANSFER_USER_ACTIVITY_SUCESS:
      return {
        message: action.data.message,
        Description: action.data.message,
      };
    case ActionTypes.RECACULATE_lEADERBOARD_SUCESS:
      return {
        message: action.data.message,
        Description: action.data.message,
      };
    case ActionTypes.REMOVE_USER_SUCESS:
      return {
        message: action.data.message,
        Description: action.data.message,
      };
    case ActionTypes.TRANSFER_USER_FOLLOWER_SUCESS:
      return {
        message: action.data.message,
        Description: action.data.message,
      };
    case ActionTypes.CHANGE_CHALLENGE_ORGANIZER_SUCESS:
      return {
        message: action.data.message,
        Description: action.data.message,
      };
    case ActionTypes.COPY_REWARD_FROM_OTHER_CHALLENGE_SUCESS:
      return {
        message: action.data.message,
        Description: action.data.message,
      };
    // Default
    default:
      return {};
  }
};

export { errorReducer, successReducer };
