import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import FormControl from "@mui/material/FormControl";
import { Icon, Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { confirmAlert } from "react-confirm-alert";

import { Formik, ErrorMessage, Field, Form, } from 'formik';
import * as Yup from 'yup';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import CustomSpinner from "layouts/ProgressBar";
import { Link, useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { getActiveChallengesList, getOrganizersList, changeChallengeOrganizer } from "redux/actions/challengeActions";

function UpdateChallengeOrganizer({ getActiveChallengesListAction, getOrganizersListAction, changeChallengeOrganizerAction,
  challengeReducer, organizersListReducer, changeChallengeOrganizerReducer }) {

  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formInitialValues = {
    "challengeDetails": null,
    "organizerDetails": null,
  };

  const formValidationSchema = {
    challengeDetails: Yup.object().required("Please choose the challange."),
    organizerDetails: Yup.object().required("Please choose the organizer."),
  };

  useEffect(() => {
    getActiveChallengesListAction();
    getOrganizersListAction();
  }, []);

  useEffect(() => {
    if (changeChallengeOrganizerReducer?.sucess) {
      setIsLoading(false);
      // setTimeout(() => {
      //   navigate("/support_tool/transfer-user-activity")
      // }, 2000);
    }
    else {
      setIsLoading(false);
    }
  }, [changeChallengeOrganizerReducer])

  const onFormSubmit = (values, actions) => {
    confirmAlert({
      title: "",
      message: "Are you sure to change organizer?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setIsLoading(true)
            changeChallengeOrganizerAction({
              "challengeId": values.challengeDetails.challengeId,
              "organizer": values.organizerDetails,
            })
          }
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mx={0} mb={2} mt={3} py={3} px={2} variant="gradient" bgColor="success" borderRadius="lg" coloredShadow="info">
          <MDTypography variant="h6" color="white">
            Update Challenge Organizer
          </MDTypography>
        </MDBox>
        {isError && (
          <MDSnackbar
            color="error" icon="warning" title={isError} content={isError} open={isError} bgWhite
            onClose={() => { setIsError(false) }}
            close={() => { setIsError(false) }}
          />
        )}
        <MDBox mb={3}>
          {(organizersListReducer?.loading ||
            challengeReducer?.loading || isLoading) ?
            <CustomSpinner /> :

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Formik
                enableReinitialize={true}
                initialValues={formInitialValues}
                validationSchema={Yup.object().shape(formValidationSchema)}
                onSubmit={onFormSubmit}
              >
                {formikProps => (
                  <Form>
                    <Card>
                      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={3} px={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <Field
                              as={Autocomplete}
                              name="challengeDetails"
                              options={challengeReducer?.challengesList}
                              value={formikProps.values?.challengeDetails}
                              getOptionLabel={(option) => option.challengeName}
                              onChange={(event, value) => {
                                formikProps.setFieldValue("challengeDetails", value);
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.challengeId}>

                                  {option?.challengeImagesUrl && option?.challengeImagesUrl.length
                                    ? <img
                                      srcSet={option?.challengeImagesUrl[0]}
                                      src={option?.challengeImagesUrl[0]}
                                      alt={option.challengeName}
                                      className="communityImg"
                                    />
                                    : <Icon fontSize={"large"} className="communityImg communityImgIcon" >account_circle</Icon>
                                  }
                                  &nbsp;&nbsp;
                                  <span>{option.challengeName}</span>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Challenges"
                                  inputProps={{
                                    ...params.inputProps,

                                  }}
                                />
                              )}
                            />

                            <ErrorMessage name="challengeDetails" component="div" className="error-message" />
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>

                            <Field
                              as={Autocomplete}

                              defaultValue={formikProps.values?.organizerDetails}
                              value={formikProps.values?.organizerDetails}
                              name="organizerDetails"
                              options={organizersListReducer.organizersList}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                formikProps.setFieldValue("organizerDetails", value);
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>

                                  {option?.imageUrl && option?.imageUrl != ""
                                    ? <img
                                      srcSet={option.imageUrl}
                                      src={option.imageUrl}
                                      alt={option.name}
                                      className="communityImg"
                                    />
                                    : <Icon fontSize={"large"} className="communityImg communityImgIcon"  >account_circle</Icon>
                                  }
                                  &nbsp;&nbsp;
                                  <span>{option.name}</span>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Organizers"
                                  inputProps={{
                                    ...params.inputProps,

                                  }}
                                />
                              )}
                            />
                            <ErrorMessage name="organizerDetails" component="div" className="error-message" />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Card>
                    <MDBox display="flex" justifyContent="end" alignItems="center" mt={3} >
                      <MDButton type="submit" size="medium" color="success">Update Organizer</MDButton>
                      &nbsp;
                      <Link to="/dashboard">
                        <MDButton type="button" size="medium" color="error">Back</MDButton>
                      </Link>
                    </MDBox>
                  </Form>
                )}
              </Formik>
            </LocalizationProvider>
          }
        </MDBox>
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

const mapStateToProps = (state) => {
  return {
    challengeReducer: state.challengeReducer,
    organizersListReducer: state.organizersListReducer,
    changeChallengeOrganizerReducer: state.changeChallengeOrganizerReducer
  };
};

export default connect(mapStateToProps, {
  getActiveChallengesListAction: getActiveChallengesList,
  getOrganizersListAction: getOrganizersList,
  changeChallengeOrganizerAction: changeChallengeOrganizer

})(UpdateChallengeOrganizer);