import MyImage from "../../assets/images/image.jpg";

function Basic() {
  const appStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "280vh",
    margin: 0,
    backgroundColor: "#000", // Optional: background color
  };

  const imgStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
  };

  return (
    <>
      <div style={appStyle}>
        <img src={MyImage} alt="Image" style={imgStyle} useMap="#image-map" />

        <map name="image-map">
        <area 
          target="" 
          alt="Microsoft Form" 
          title="Microsoft Form" 
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=DzBIyn8LE0CoYRnHcADgUyZ-lfYANmFLtXMBSsdsXjNUN1JFNUc4RzlJNTFKTlM2TjQ0WTFLVlRRNS4u" 
          coords="1054,1076,695,825"
          shape="rect" 
        />
        <area 
          target="" 
          alt="Microsoft Form" 
          title="Microsoft Form" 
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=DzBIyn8LE0CoYRnHcADgUyZ-lfYANmFLtXMBSsdsXjNUN1JFNUc4RzlJNTFKTlM2TjQ0WTFLVlRRNS4u" 
          coords="468,1831,102,1444,307,1503,1098,789"
          shape="rect" 
        />
      </map>

      </div>

    </>
  );
}

export default Basic;
